export default function(device, region, skin) {
	//console.log(`[relportLink.js] device = ${device}`);
	if (region === 'line') {
		return 'https://help2.line.me/dictionary/web/'
	} else if (region === 'globalEnen') {
		if (skin == "ko") { //https://oss.navercorp.com/skywalker/dict_platform/issues/5004
			return 'https://help.naver.com/alias/contents2/dictionary/dic_new2.naver';
		} else {
			return 'https://help.naver.com/service/24549/contents/14997?lang=en'
		}
	} else if (region === 'enxx') {
		if (skin == "ko") { //https://oss.navercorp.com/skywalker/dict_platform/issues/5004
			return 'https://help.naver.com/alias/contents2/dictionary/dic_new2.naver';
		} else {
			return 'https://help.naver.com/service/24549/contents/14997?lang=en'
		}
	} else if (region === 'news') {
		if (device === 'm') {
			return 'https://help.naver.com/alias/contents2/dictionary/dic_new3.naver'
		} else {
			return 'https://help.naver.com/alias/contents2/dictionary/dic_new2.naver'
		}
	} else {
		if (skin === 'ko') {
			return 'https://help.naver.com/alias/contents2/dictionary/dic_new2.naver'
		} else {
			return 'https://help.naver.com/service/18092/category/bookmark?lang=en'
		}
	}
}
